//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import store from "@/store";
import { validateForms } from "@/common/lib/validate";
import { gridIcon, boxIcon, eyeIcon, eyeOffIcon, databaseIcon, checkCircleIcon, fileTextIcon, creditCardIcon, geneticDnaIcon, cableErrorIcon } from "@debionetwork/ui-icons";
import NavigationDrawer from "@/common/components/NavigationDrawer";
import Navbar from "@/common/components/Navbar.vue";
import maintenancePageLayout from "@/views/Dashboard/maintenancePageLayout";
import errorMessage from "@/common/constants/error-messages";
import localStorage from "@/common/lib/local-storage";
export default {
  name: "MainPage",
  mixins: [validateForms],
  components: {
    NavigationDrawer,
    Navbar,
    maintenancePageLayout
  },
  data: () => ({
    checkCircleIcon,
    eyeIcon,
    eyeOffIcon,
    cableErrorIcon,
    showModalError: false,
    showModalPassword: false,
    pageError: null,
    showPassword: false,
    success: false,
    error: null,
    password: null,
    navs: [{
      text: "Dashboard",
      disabled: false,
      active: false,
      route: "customer-dashboard",
      icon: gridIcon
    }, {
      text: "My Test",
      disabled: false,
      active: false,
      route: "my-test",
      icon: boxIcon
    }, {
      text: "My Health Record",
      disabled: false,
      active: false,
      route: "customer-phr",
      icon: fileTextIcon,
      withSub: true,
      subMenu: [{
        text: "Menstrual Calendar",
        route: "menstrual-calendar",
        active: false
      }, {
        text: "Second Opinion",
        route: "second-opinion",
        active: false
      }]
    }, {
      text: "My Genetic Data",
      disabled: false,
      active: false,
      route: "customer-genetic-data",
      icon: geneticDnaIcon
    }, {
      text: "Data Bounty",
      disabled: false,
      active: false,
      route: "customer-data-bounty-maintenance",
      icon: databaseIcon
    },
    // TO ADJUST ONCE THE FEATURE IS READY
    {
      text: "Payment History",
      disabled: false,
      active: false,
      route: "customer-payment-history",
      icon: creditCardIcon
    }]
  }),
  computed: {
    ...mapState({
      lastEventData: state => state.substrate.lastEventData,
      wallet: state => state.substrate.wallet,
      localListNotification: state => state.substrate.localListNotification,
      mnemonicData: state => state.substrate.mnemonicData,
      lastBlockData: state => state.substrate.lastBlockData,
      api: state => state.api
    }),
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    computeNavs() {
      const setActive = name => {
        return this.$route.name === name || this.$route.meta.parent === name;
      };
      return this.navs.map(nav => ({
        ...nav,
        active: setActive(nav.route)
      }));
    },
    computeButtonActive() {
      return !/(\/customer\/request-test)/.test(this.$route.path);
    }
  },
  watch: {
    $route(val) {
      var _val$query;
      this.pageError = null;
      if (val !== null && val !== void 0 && (_val$query = val.query) !== null && _val$query !== void 0 && _val$query.error) this.showModalError = true;
    },
    lastEventData(event) {
      if (event !== null) {
        this.$store.dispatch("substrate/addListNotification", {
          address: this.wallet.address,
          event: event,
          block: this.lastBlockData,
          role: "customer" //TODO: move to global enum variable
        });
      }
    }
  },

  async created() {
    if (!this.mnemonicData) this.showModalPassword = true;
    await this.getListNotification();
  },
  rules: {
    password: [val => !!val || errorMessage.PASSWORD(8)]
  },
  methods: {
    handlePageError(error) {
      this.pageError = error;
    },
    async getListNotification() {
      await this.$store.dispatch("substrate/getListNotification", {
        address: this.wallet.address,
        role: "customer",
        block: this.lastBlockData
      });
    },
    goToRequestTestPage() {
      this.$router.push({
        name: "customer-request-test"
      });
    },
    goToUploadPHR() {
      this.$router.push({
        name: "customer-phr-create"
      });
    },
    goToRequestAnalysis() {
      this.$router.push({
        name: "customer-request-analysis"
      });
    },
    goToDashboard() {
      this.showModalError = false;
      this.$router.push({
        name: "customer-dashboard"
      });
    },
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async handleSubmitPassword() {
      try {
        await this.wallet.unlock(this.password);
        await store.dispatch("substrate/getEncryptedAccountData", {
          password: this.password
        });
        this.success = true;
        setTimeout(() => {
          this.showModalPassword = false;
        }, 1300);
      } catch (e) {
        if (e.message === "Unable to decode using the supplied passphrase") {
          return this.error = errorMessage.INCORRECT("Password");
        }
        this.error = e.message;
      }
    },
    signOut() {
      this.$router.push({
        name: "landing-page"
      });
      const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
      accounts.forEach(a => {
        window.localStorage.removeItem(a);
      });
      localStorage.clear();
      this.clearAuth();
      this.clearWallet();
    }
  }
};